<script setup lang="ts">
import { onMounted, onUnmounted, watch } from "vue";
import { useToggle } from "@vueuse/core";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import SuccessIcon from "@magnit/icons/src/assets/icons/circle-success-24-system.svg";
import WarningIcon from "@magnit/icons/src/assets/icons/triangle-warning-24-system.svg";
import type { IToastsProps } from "~/components/VToasts/VToasts.types";

const props = withDefaults(defineProps<IToastsProps>(), {
  duration: 4000,
});
const emit = defineEmits<{
  close: [];
}>();

const [active, toggle] = useToggle();
let timer: ReturnType<typeof setTimeout> | null = null;

const setQueue = () => {
  timer = setTimeout(() => {
    toggle(false);
  }, props.duration);
};

onMounted(() => {
  setQueue();
  toggle(true);
});

onUnmounted(() => {
  if (timer) clearTimeout(timer);
});

watch(active, (next) => {
  if (next === false) emit("close");
});
</script>

<template>
  <div class="toast__container">
    <transition name="toast_fade">
      <div v-if="active" class="toast" @click="toggle(false)">
        <div class="toast__inner">
          <VIcon>
            <WarningIcon
              v-if="type === 'warning'"
              class="toast__icon-warning"
            />
            <SuccessIcon
              v-if="type === 'success'"
              class="toast__icon-success"
            />
          </VIcon>

          <div class="toast__text">
            <VText color="invert-primary" font="body-small-accent-low">
              {{ props.text }}
            </VText>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss">
.toast__container {
  position: fixed;
  top: 16px;
  left: 0;
  width: 100%;
  z-index: var(--pl-z-index-toast);
}

.toast {
  margin-bottom: 8px;

  &__inner {
    display: flex;
    margin: 0 20px auto;
    padding: 12px;
    max-width: 100%;
    background-color: var(--pl-toast-background-default);
    box-shadow: var(--pl-shadow-xl);
    border-radius: var(--pl-toast-radius-s);
    cursor: pointer;
  }

  &__text {
    margin-left: 12px;
    word-break: break-word;
  }
}

.toast_fade-enter-active,
.toast_fade-leave-active {
  transition: opacity 0.3s ease;

  & .toast__inner {
    transition: transform 0.3s ease;
    transform: translateY(0);
  }
}

.toast_fade-enter-from,
.toast_fade-leave-to {
  opacity: 0;

  & .toast__inner {
    transform: translateY(-100%);
  }
}

@media (--pl-viewport-from-m) {
  .toast__inner {
    margin: 0 32px auto;
  }
}

@media (--pl-viewport-from-l) {
  .toast__inner {
    margin: 0 auto;
    max-width: fit-content;
  }
}
</style>
